@import 'dist/medigle-reserve-lib/reserve_form/css/import.css';
@import 'quill/dist/quill.snow.css';

a:hover {
  cursor: pointer;
}

mat-select {
  .mat-mdc-select-trigger {
    padding: 5px 15px 5px 15px;
    border: 1px solid rgba(34, 36, 38, 0.15);
  }
}
